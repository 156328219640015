
import { Component, Vue } from 'vue-property-decorator';
import SingleHeader from '@/components/singleHeader.vue';
import SingleFooter from '@/components/singleFooter.vue';

@Component({
    name: "EmulateDetail",
    components: {
        SingleHeader,
        SingleFooter,
    },
})
export default class ProductDetail extends Vue {
    private baseUrlIframe = process.env.VUE_APP_URL;
    private baserUrlWis3d = 'https://3dmall.morewiscloud.com';
    private useInfo: any = {}; // 用户信息
    private active: number = 5;
    private goodsId: any = '';
    private typeId: any = '';
    private goodsTab: any = {};
    private infoFlag: boolean = false;
    private baseUrlSub: any = '';
    private descText: any = '';
    get typeName() {
        const length = this.goodsTab.goodsCategories.length;
        return length ? this.goodsTab.goodsCategories[length - 1] : '-';
    }
    private created() {
        if (this.$route.fullPath.indexOf('goodsId=') > -1) {
            this.goodsId = unescape(this.$route.query.goodsId + '');
            this.typeId = unescape(this.$route.query.typeId + '');
            if (this.typeId === 'SCENE') {
                this.baseUrlSub = '/wis3d_edit/PreviewApp?appFile=';
                this.descText = "场景";
            } else if (this.typeId === 'BI') {
                this.baseUrlSub = '/wis3d_edit/PreviewApp?appFile=';
                this.descText = "看板";
            } else { // 'MODEL'
                this.baseUrlSub = '/wis3d_cms/previewModel?modelUrl=';
                this.descText = "模型";
            }
            this.goodsDetail();
        }
    }
    private mounted() {
        // 切换页面时滚动条自动滚动到顶部
        window.scrollTo(0, 0);
    }
    private goodsDetail() {
        this.infoFlag = false;
        const paramData: any = {};
        paramData.goodsId = this.goodsId;
        paramData.typeId = this.typeId;
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/mall/wis3d/goods/detail`)
        .then((res: any) => {
            this.goodsTab = res || {};
            this.infoFlag = true;
        });
    }
    private parser(value: any, max: number, min: number) {
        if (value === undefined) {
            return min;
        }
        const v = parseInt(value, 10);
        if (!isNaN(v)) {
            if (v > max) {
                return max;
            } else if (v < min) {
                return min;
            } else {
                return v;
            }
        }
        return min;
    }
    private async customLink() {
        if (!await this.$isLogin()) {
            this.loginTips();
            return;
        }
        const sellerTab = this.goodsTab.customerServiceInfo;
        const baseCustom = '/gateway/rest/v1/chat/morewisCloud/wisLogin?moreToken=';
        const customUrl = `${sellerTab.ntalkChatUrl}${baseCustom}${sellerTab.ntalkToken}&toUser=${sellerTab.customAccount}`;
        window.open(customUrl);
    }
    private loginTips() {
        this.$confirm('请登录后操作', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(() => {
            this.$router
            .push({
                path: '/login',
                query: {
                    redirect: window.location.href,
                },
            }).catch((err) => err);
        }).catch(() => {
            // ...
        });
    }
    private gotoPage(index: number) {
        if (index === 1) {
            this.$router.push('/index');
        } else if (index === 5) {
            this.$router.push('/industryLibrary');
        }
    }
}

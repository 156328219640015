
import { Component, Vue } from 'vue-property-decorator';
import SingleHeader from '@/components/singleHeader.vue';
import SingleFooter from '@/components/singleFooter.vue';
import DriveList from './component/DriveList.vue';
import EmulateList from './component/EmulateList.vue';
@Component({
    name: "IndustryLibrary",
    components: {
        SingleHeader,
        SingleFooter,
        DriveList,
        EmulateList,
    },
})
export default class IndustryLibrary extends Vue {
    private contentTitle: string = "驱动列表";
    private useInfo: any = {}; // 用户信息
    private disabledScroll: boolean = false;
    private tabBarType: number = 1;
    private loadScroll() {
        // ...
        // if (this.tabBarType === 1) {
        //     (this.$refs.driveList as DriveList).loadScroll();
        // } else if (this.tabBarType === 2) {
        //     (this.$refs.emulateList as EmulateList).loadScroll();
        // }
    }
    private changeTabBar(type: number) {
        this.disabledScroll = false;
        this.tabBarType = type;
    }
    private handleRefresh(disabledScroll: boolean) {
        this.disabledScroll = disabledScroll;
    }
}

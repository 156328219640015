
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'DriveList',
    components: {
    },
})
export default class DriveList extends Vue {
    @Prop(Boolean) private disabledScroll!: boolean;
    private defaultProductImg: any = require('@/assets/images/detail/moreCloud.png');
    private searchParam: any = {
        pageSize: 24,
        pageNo: 1,
        industry: [],
        vendor: '',
        model: '',
        title: '',
    };
    private loadFlag: boolean = false;
    private total: number = 0;
    private props: any = {
        checkStrictly: true,
        children: 'child',
        label: 'name',
        value: 'id',
    };
    private driveData: any = [];
    private industryTree: any = [];
    public loadScroll() {
        console.log('loadScroll');
        // this.searchParam.pageNo += 1;
        this.getDriveList(false);
    }
    @Emit('handleRefresh')
    private refreshTodo(disScroll: boolean): boolean {
        return disScroll;
    }
    private created() {
        this.$nextTick(() => {
            // 加载驱动列表
            this.getDriveList(true);
            // 加载行业
            this.getIndustryTree();
        });
    }
    // vue的mounted方法中, 写设置页面滚动条的这个方法
    private mounted() {
        // 切换页面时滚动条自动滚动到顶部
        window.scrollTo(0, 0);
    }
    private getIndustryTree() {
        const paramData: any = {};
        paramData.pid = 0;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/drive/industry')
        .then((res: any) => {
            if (res && res.length > 0) {
                this.industryTree = res;
            } else {
                this.industryTree = [];
            }
        });
    }
    private setLeaf(res: any) {
        res.forEach((item: any) => {
            if (item.child.length > 0) {
                item.leaf = true;
                this.setLeaf(item.child);
            } else {
                item.leaf = false;
                item.child = null;
            }
        });
        return res;
    }
    private industryLazyLoad(node: any, resolve: any) {
        const { level } = node;
        const paramData: any = {};
        paramData.pid = node.value ? node.value : 0;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/drive/industry')
        .then((res: any) => {
            const nodes: any = [];
            if (res && res.length > 0) {
                res.forEach((item: any) => {
                    nodes.push({
                        value: item.id,	// 选项值
                        label: item.name,	// 选项名
                        leaf: level >= 3,	// 是否为末尾
                    });
                });
            }
            resolve(nodes);
        });
    }
    private handleCascadeChange() {
        const sysCascadeRef: any = this.$refs.sysCascade;
        const panelRefs: any = sysCascadeRef.$refs.panel;
        if (sysCascadeRef) {
            const children = sysCascadeRef.getCheckedNodes();
            if (children && children.length > 0 && children[0].level >= 4) {   // 判断有没有下级
                sysCascadeRef.dropDownVisible = false; // 监听值发生变化就关闭它
            } else if (children && children.length > 0 && children[0].children.length === 0) { // 判断子级有没有值，没有就加载
                panelRefs.lazyLoad(panelRefs.getCheckedNodes()[0]);
            }
        }
    }
    private handleCurrentChange(val: number) {
        this.searchParam.pageNo = val;
        this.getDriveList(false, true);
    }
    private onSearch() {
        this.searchParam.pageNo = 1;
        this.getDriveList(true);
    }
    private getDriveList(isFirst: boolean, scrollSwitch: boolean = false) {
        this.refreshTodo(true);
        const paramData: any = Object.assign({}, this.searchParam);
        if (paramData.industry.length < 4 && paramData.industry.length > 0) {
            paramData.industry = paramData.industry.toString();
        } else {
            paramData.industry = paramData.industry.toString();
        }
        if (this.searchParam.pageNo === 1 && isFirst) {
            this.driveData = [];
            this.loadFlag = false;
        } else {
            paramData.notLoading = true;
        }
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/goods/goods/drive/page')
        .then((res: any) => {
            this.loadFlag = true;
            if (res && res.total > 0) {
                this.driveData = res.list;
                this.total = res.total;
            } else {
                this.driveData = [];
                this.total = 0;
            }
            if (scrollSwitch) {
                this.goAnchor();
            }
            // if (res && res.total > 0 && (res.total / this.searchParam.pageSize - this.searchParam.pageNo) > 0 ) {
            //     this.refreshTodo(false);
            // } else {
            //     this.refreshTodo(true);
            // }
        });
    }
    private gotoDetail(item: any) {
        this.$router.push({ path: "/detail", query: { goodsId: item.goodsId }})
        .catch((err) => err);
    }
    private goAnchor() {
        document.getElementById(`search_head`)!.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    }
}

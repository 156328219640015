
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({
    name: 'EmulateList',
    components: {
    },
})
export default class EmulateList extends Vue {
    @Prop(Boolean) private disabledScroll!: boolean;
    private defaultProductImg: any = require('@/assets/images/detail/moreCloud.png');
    private typesData: any = [];
    private categoryData: any = [];
    private categoryShowData: any = [];
    private categoryLimit: number = 9;
    private emulateData: any = [];
    private selectedCategory: any = null;
    private searchParam: any = {
        pageSize: 24,
        pageNo: 1,
        typeId: "",
        categoryMasking: '',
    };
    private loadFlag: boolean = false;
    private total: number = 0;
    public loadScroll() {
        this.getTypesData();
    }
    @Emit('handleRefresh')
    private refreshTodo(disScroll: boolean): boolean {
        return disScroll;
    }
    private created() {
        this.$nextTick(() => {
            // 加载类别
            this.getTypesData();
        });
    }
    private mounted() {
        // 切换页面时滚动条自动滚动到顶部
        window.scrollTo(0, 0);
    }
    private handleCurrentChange(val: number) {
        this.searchParam.pageNo = val;
        this.getEmulateList(false, true);
    }
    private typeChange(identify: any) {
        if (this.searchParam.typeId === identify) {
            return;
        }
        this.searchParam.typeId = identify;
        this.searchParam.pageNo = 1;
        this.getCategoryData();
    }
    private categoryChange(obj: any) {
        if (this.selectedCategory.id === obj.id) {
            return;
        }
        this.selectedCategory = obj;
        this.searchParam.pageNo = 1;
        this.getEmulateList(true);
    }
    private getEmulateList(isFirst: boolean, scrollSwitch: boolean = false) {
        this.refreshTodo(true);
        this.searchParam.categoryMasking = this.selectedCategory.masking;
        const paramData: any = Object.assign({}, this.searchParam);
        if (this.searchParam.pageNo === 1 && isFirst) {
            this.emulateData = [];
            this.loadFlag = false;
        } else {
            paramData.notLoading = true;
        }
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/mall/wis3d/goods')
        .then((res: any) => {
            this.loadFlag = true;
            if (res && res.total > 0) {
                // res.list.forEach((item: any) => {
                //     this.emulateData.push(item);
                // });
                this.emulateData = res.list;
                this.total = res.total;
            } else {
                this.emulateData = [];
                this.total = 0;
            }
            if (scrollSwitch) {
                this.goAnchor();
            }
            // if (res && res.total > 0 && (res.total / this.searchParam.pageSize - this.searchParam.pageNo) > 0 ) {
            //     this.refreshTodo(false);
            // } else {
            //     this.refreshTodo(true);
            // }
        });
    }
    private getTypesData() {
        const paramData: any = {};
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/mall/wis3d/types')
        .then((res: any) => {
            if (res && res.length > 0) {
                this.typesData = res;
                this.searchParam.typeId = res[0].identify;
                // 加载类型
                this.getCategoryData();
            } else {
                this.typesData = [];
            }
        });
    }
    private getCategoryData() {
        const paramData: any = {};
        paramData.typeId = this.searchParam.typeId;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/mall/wis3d/category')
        .then((res: any) => {
            if (res && res.length > 0) {
                this.categoryData = res;
                this.categoryData.unshift({
                    id: -1,
                    masking: "",
                    name: "全部",
                });
            } else {
                this.categoryData = [];
                this.categoryData.push({
                    id: -1,
                    masking: "",
                    name: "全部",
                });
            }
            this.moreCategory(this.categoryData);
            this.selectedCategory = this.categoryData[0];
             // 加载仿真模型列表
            this.getEmulateList(true);
        });
    }
    private moreCategory(arr: any) {
        if (arr.length > this.categoryLimit) {
            this.categoryShowData = this.categoryData.slice(0, this.categoryLimit);
        } else {
            this.categoryShowData = this.categoryData;
        }
    }
    private gotoDetail(item: any) {
        this.$router.push({ path: "/emulateDetail", query: { goodsId: item.goodsId, typeId: this.searchParam.typeId }})
        .catch((err) => err);
    }
    private goAnchor() {
        document.getElementById(`selected_head`)!.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    }
}
